<template>
  <section class="commercial">
    <div class="container">
      <div class="iconTextIntro">
        <span class="icon-AC_icon-business-use"></span>
        <h3>{{ commercial.Title }}</h3>
        <p>{{ commercial.Subtitle }}</p>
      </div>
      <div class="fullWidthImage">
        <img src="http://placehold.it/2160x800" />
      </div>
      <div class="explore__assistanceBlocks assistantBlocks">
        <div class="assistantBlocks__block assistantBlockPrimary">
          <div class="assistantBlockPrimary__title">
            <h3>{{ commercial.BodyTitle }}</h3>
          </div>
          <div class="assistantBlockPrimary__content">
            <p>
              {{ commercial.BodyText }}
            </p>
          </div>
        </div>
        <div
          class="assistantBlocks__block assistantBlock assistantBlock--large assistantBlock--buttons"
        >
          <div class="assistantBlock__content">
            <router-link to="/change/quiz" class="btn btn--primary"
              >find a vehicle for your company

              <span class="icon-AC_interface-double-chevron-right"></span>
            </router-link>
            <router-link to="/change/quiz" class="btn btn--primary"
              >Find your perfect ev

              <span class="icon-AC_interface-double-chevron-right"></span>
            </router-link>
          </div>
        </div>
        <div
          class="assistantBlocks__block assistantBlockPrimary assistantBlockPrimary--tagline"
        >
          <div class="assistantBlockPrimary__title">
            <p>
              <span class="icon-AC_icon-user"></span>
              speak to an innovation genius about our business seminars
            </p>
          </div>
        </div>
      </div>
      <div class="continue">
        <div class="continue__wave">
          <img src="@/assets/img/4.png" alt="" />
        </div>
        <div class="continue__content continueContent">
          <div class="continueContent__title">
            <p>continue</p>
            <h3>your journey</h3>
          </div>
          <router-link
            :to="commercial.BottomLink"
            class="continueContent__button continueContentBtn"
          >
            <div class="circleIcon">
              <span class="icon-AC_icon-electric-vehicle"></span>
            </div>
            <div class="continueContentBtn__content">
              <h4>{{ commercial.BottomLinkName }}</h4>
              <p>{{ commercial.BottomLinkDescription }}</p>
            </div>
          </router-link>
          <div class="continueContent__home">
            <router-link to="/home" class="btn btn--outline btn--icon">
              <span class="icon-AC_interface-home"></span>
              Home menu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      commercial: "getCommercial"
    })
  },
  components: {},
  methods: {}
};
</script>
