<template>
  <section class="infoHub">
    <div class="container">
      <div class="menu slide-in">
        <div class="menu__wave">
          <img src="@/assets/img/1.png" alt="" />
          <div class="menuLogo">
            <span class="icon-AC_brand-full-logo"
              ><span class="path1"></span><span class="path2"></span
              ><span class="path3"></span
            ></span>
            <p>Welcome to the <strong>arnold clark information hub</strong></p>
          </div>
        </div>
        <div class="menu__nav">
          <nav>
            <ul>
              <li v-for="(m, index) in menu" :key="index">
                <router-link :to="m.Link">
                  <div :class="`circleIcon circleIcon--${getClassName(m)}`">
                    <span :class="m.Icon"></span>
                  </div>
                  <div class="linkText">
                    <h3>{{ m.Title }}</h3>
                    <p>{{ m.Description }}</p>
                  </div>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="carCarousel" v-if="vehicles">
      <div class="carCarousel__title">
        <div class="exploreTagline">
          <p>explore the range</p>
          <template v-if="showInitialMakeModel">
            <h3>{{ vehicles[0].Make }} {{ vehicles[0].Model }}</h3>
          </template>
          <template v-else>
            <h3>{{ make }} {{ model }}</h3>
          </template>
          <!-- <span>1.6 GDi Hybrid Premium 5dr DCT</span> -->
        </div>
      </div>
      <slick
        v-if="vehicles"
        ref="slick"
        :options="slickOptions"
        @afterChange="handleAfterChange"
        class="slickDiv"
      >
        <img
          v-for="(vehicle, index) in vehicles.slice(0, vehicles.length)"
          @click="openVehicleModal(vehicle)"
          :key="index"
          :src="vehicle.FeaturedVehicleImage"
          data-test="loop"
        />
      </slick>
      <div class="carCarousel_controls" :class="isChrome ? 'Chrome' : ''">
        <ul>
          <li class="prev">
            <button @click="showPrev">
              <div class="angle-left transparent"></div>
              <div class="angle-left kinda-transparent"></div>
              <div class="angle-left"></div>
            </button>
          </li>
          <li class="carCarousel_controls_text">SWIPE</li>
          <li class="next">
            <button @click="showNext">
              <div class="angle-right"></div>
              <div class="angle-right kinda-transparent"></div>
              <div class="angle-right transparent"></div>
            </button>
          </li>
        </ul>
        <p>{{ currentSlideNumber }} of {{ vehicles.length }}</p>
      </div>
    </div>

    <div class="whiteWaveFooter"></div>
    <!-- <lottie-animation
      ref="lottieanimtwo"
      :class="`waveBottom ${!showLottie ? 'hiddenWave' : ''}`"
      path="lottie/New AC Wave_02_v2.json"
      :loop="true"
      :width="2160"
    /> -->
  </section>
</template>
<script>
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

import { mapGetters, mapActions } from "vuex";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";

export default {
  data() {
    return {
      slickOptions: {
        slidesToShow: 2.5,
        arrows: false,
        dots: false,
        centerMode: true,
        infinite: false,
        touchThreshold: 500000,
        centerPadding: "00px"
      },
      make: "",
      model: "",
      currentSlideNumber: 1
    };
  },
  computed: {
    ...mapGetters({
      vehicles: "getVehicles",
      menu: "getMenuItems",
      activeModal: "getModalDrive",
      featuredVehicles: "getFeaturedVehicles"
    }),
    showInitialMakeModel() {
      if (this.make === "" && this.model === "") return true;
      return false;
    },
    showLottie() {
      if (this.$route.name === "Home") {
        return false;
      }
      return true;
    },
    isChrome(){
      return window.chrome
    }
  },
  methods: {
    ...mapActions({
      setCurrentVehicle: "setCurrentVehicle",
      setModal: "toggleModal",
      setCarCategory: "setCarCategory"
    }),
    openVehicleModal(vehicle) {
      this.setCurrentVehicle(vehicle);
      let payload = vehicle.CapCode + vehicle.Make;
      this.setModal(payload);
      this.$router.push("/drive");
    },
    handleAfterChange(event, slick, currentSlide) {
      this.make = this.vehicles[currentSlide].Make;
      this.model = this.vehicles[currentSlide].Model;
      this.currentSlideNumber = currentSlide + 1;
    },
    getMenuIcon(item) {
      let icon;
      switch (item.Icon) {
        case "Icon Learn":
          icon = "icon-EnginePumpBolt";
          break;
        case "Icon Power Up":
          icon = "icon-AC_icon-charge-point-1";
          break;
        case "Icon Discover":
          icon = "icon-AC_icon-type-PHEV";
          break;
        case "Icon Explore":
          icon = "icon-AC_icon-electric-vehicle";
          break;
        case "Icon Business":
          icon = "icon-AC_icon-business-use";
          break;
      }
      return icon;
    },
    getClassName(item) {
      let className;
      switch (item.Title) {
        case "Learn":
          className = "learn";
          break;
        case "Charge":
          className = "powerup";
          break;
        case "Drive":
          className = "discover";
          break;
        case "Change":
          className = "explore";
          break;
        case "business use":
          className = "learn";
          break;
      }
      return className;
    },
    showPrev() {
      this.$refs.slick.prev();
    },
    showNext() {
      this.$refs.slick.next();
    }
  },
  mounted() {
    // this.$refs.lottieanimtwo.speed = 0.04;
    this.setCarCategory("all");
  },
  components: {
    Slick
    // LottieAnimation
  }
};
</script>
