<template>
  <div class="modal">
    <div class="modal__bg" @click="() => $emit('closed')"></div>
    <div class="modal__in">
      <div class="modal__tap" @click="() => $emit('closed')">
        <h3>Tap to close</h3>
      </div>
      <div class="modal__content modalContent">
        <div class="modalContent__header">
          <template v-if="allInfo.length > 1">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="(info, index) in allInfo" :key="index">
                <video ref="video" :autoplay="index === 0 ? true : false" loop>
                  <source :src="info.Image" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </template>
          <template v-else>
            <img v-if="infoContent" :src="infoContent.Image" />
          </template>
        </div>
        <div class="modalContent__body">
          <template v-if="content.Information.length > 1">
            <div class="tabs tabs--flexGrow">
              <button
                v-for="(item, index) in content.Information"
                :key="index"
                @click="setActiveTab(index)"
                :class="
                  `tabs__item tabItem tabItem--withText ${
                    activeTab === index ? 'tabItem--active slide-in' : ''
                  }`
                "
              >
                <span :class="item.Icon"></span>
                <p>{{ item.IconText }}</p>
              </button>
            </div>
            <div
              v-for="(item, index) in content.Information"
              :key="index"
              :class="
                `content content--halfed ${
                  activeTab === index ? 'slide-in' : 'slide-out'
                }`
              "
              v-show="activeTab === index"
            >
              <div :class="`intro ${!infoContent.Subtitle ? 'negMarg' : ''}`">
                <p class="intro__subtitle">{{ item.Subtitle }}</p>
                <h3>{{ item.Title }}</h3>
                <ul class="tickList" v-if="item.List">
                  <li v-for="(item, index) in item.List" :key="index">
                    {{ item }}
                  </li>
                </ul>
                <div class="intro__icon">
                  <span :class="`${item.Icon}`"></span>
                </div>
              </div>
              <div class="description">
                <div v-html="item.Body"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-if="infoContent"
              :class="
                `content content--halfed ${
                  !infoContent.Subtitle ? 'negMarg' : ''
                }`
              "
            >
              <div class="intro">
                <p class="intro__subtitle">{{ infoContent.Subtitle }}</p>
                <h3>{{ infoContent.Title }}</h3>
                <ul class="tickList" v-if="infoContent.List">
                  <li v-for="(item, index) in infoContent.List" :key="index">
                    {{ item }}
                  </li>
                </ul>
                <div class="intro__icon">
                  <span :class="`${infoContent.Icon}`"></span>
                </div>
              </div>
              <div class="description">
                <div v-if="infoContent" v-html="infoContent.Body"></div>
              </div>
            </div>
          </template>
        </div>
        <div class="modalContent__footer">
          <a
            @click.prevent="setModal(content)"
            :href="content.Link"
            class="btn btn--primary"
            >{{ content.LinkName }}
            <span class="icon-AC_interface-double-chevron-right"></span>
          </a>
        </div>
      </div>
      <div class="modal__tap" @click="() => $emit('closed')">
        <h3>Tap to close</h3>
      </div>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  props: {
    content: {
      required: false,
      default: false
    },
    id: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      activeTab: 0,
      swiperOptions: {
        slidesPerView: 1,
        loop: false
      }
    };
  },
  computed: {
    allInfo() {
      return this.content.Information;
    },
    infoContent() {
      if (!this.content.Information) return false;
      return this.content.Information[0];
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  methods: {
    closeModal: function() {},
    setModal(content) {
      if (content.Link === "/change/") {
        this.$router.push("/change/");
      } else if (content.Link === "/drive/") {
        this.$router.push("/drive/");
      } else {
        this.$emit("setmodal", this.id + 1);
      }
    },
    setActiveTab(index) {
      this.swiper.slideTo(index, 1000);
      this.activeTab = index;
      this.$refs.video[this.swiper.activeIndex].pause();
      this.$refs.video[index].currentTime = 0;
      this.$refs.video[index].play();
    },
    getIcon(icon) {
      let iconClass;
      switch (icon) {
        case "wheel":
          iconClass = "icon-AC_icon-outline-steeringwheel";
          break;
        case "petrol":
          iconClass = "icon-AC_icon-outline-petrol";
          break;
        case "leaf":
          iconClass = "icon-AC_icon-leaf";
          break;
      }
      return iconClass;
    }
  }
};
</script>
