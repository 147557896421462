<template>
  <section class="discover" v-if="compare">
    <div class="container">
      <div class="iconTextIntro" v-if="compare">
        <span class="icon-AC_icon-electric-vehicle"></span>
        <h3>{{ compare.Title }}</h3>
        <p>{{ compare.Subtitle }}</p>
      </div>
      <transition name="pop" appear>
        <div class="compare">
          <div class="compare_subheading">
            <p>COMPARE</p>
            <h3>OUR CARS</h3>
          </div>

          <div class="vehicles__select_type">
            <Dropdown
              :options="[
                { id: 'All', name: 'ALL' },
                {
                  id: 'Battery Electric Vehicle',
                  name: 'BATTERY ELECTRIC VEHICLE'
                },
                { id: 'Plug-in Hybrid', name: 'PLUG-IN HYBRID' },
                { id: 'Hybrid Electric Vehicle', name: 'FULL HYBRID' }
              ]"
              v-on:selected="updateCategory($event)"
              :disabled="false"
              name="selectType"
              :placeholder="placeholderText"
            >
            </Dropdown>

            <!---<select name="selectType" @change="updateCategory($event); " >   
              <option value="All">All ({{allVehicles.length}})</option>          
              <option :selected="resultCategory == 'Battery Electric Vehicle'" value="Battery Electric Vehicle">BATTERY ELECTRIC VEHICLE ({{allBEV.length}}) </option> 
              <option :selected="resultCategory == 'Plug-in Hybrid' || resultCategory == 'Plug-in Hybrid Electric Vehicle'" value="Plug-in Hybrid">PLUG-IN HYBRID ({{allPlugInHybrid.length}})</option>
              <option :selected="resultCategory == 'Full Hybrid' || resultCategory == 'Hybrid Electric Vehicle'" value="Full Hybrid">FULL HYBRID ({{allFullHybrid.length}})</option>
          </select>--->
            <div class="divider"></div>
          </div>

          <div class="vehicles__select_cars">
            <p>Choose two models to compare specifications</p>
            <div class="vehicles__select_cars_selectContainer">
              <Dropdown
                :options="selectedVehicles"
                v-on:selected="setActiveVehicleOne($event)"
                :disabled="false"
                name="SelectCar1"
                :maxItem="50"
                placeholder="CHOOSE ONE"
              >
              </Dropdown>
              <!---
            <select             
              name="SelectCar1" @change="setActiveVehicleOne($event);">   
              <option value="" selected disabled hidden>CHOOSE ONE</option>
              <option
                v-for="(vehicle, index) in selectedVehicles"
                :key="index"
                :value="vehicle.CapCode"
              >{{ vehicle.Make.toUpperCase() }} {{ vehicle.Model.toUpperCase() }}</option> 
            </select>--->
              <h2>&</h2>
              <Dropdown
                :options="selectedVehicles"
                v-on:selected="setActiveVehicleTwo($event)"
                :disabled="false"
                name="SelectCar2"
                :maxItem="50"
                placeholder="CHOOSE ONE"
              >
              </Dropdown>
              <!---<select name="SelectCar2" @change="setActiveVehicleTwo($event);" > 
              <option value="" selected disabled hidden>CHOOSE ONE</option>             
              <option
                v-for="(vehicle, index) in selectedVehicles"
                :key="index"
                :value="vehicle.CapCode"
              >{{ vehicle.Make }} {{ vehicle.Model }}</option>
            </select> --->
            </div>
            <div class="divider"></div>

            <button
              v-if="!comparisonViewActive"
              class="btn btn--primary"
              :disabled="!currentVehicle || !currentVehicleTwo"
              @click="showComparisonView"
            >
              Go
              <span class="icon-AC_interface-double-chevron-right"></span>
            </button>
          </div>
          <Transition name="fade-slow">
            <div
              class="vehicles__selected_container"
              v-if="comparisonViewActive"
              key="selectVehicles"
            >
              <Transition name="fade-slow">
                <div
                  class="vehicles__selected_left vehicles__selected_inner_container"
                  ref="leftSelected"
                  v-if="currentVehicle"
                  :key="chosenVehicleOneKey"
                >
                  <div class="compare__details compareDetails">
                    <div class="compareDetails__title">
                      <h2>
                        {{ currentVehicle.Make }} {{ currentVehicle.Model }}
                      </h2>
                      <p>{{ currentVehicle.Derivative }}</p>
                    </div>
                    <div class="compareDetails__img">
                      <img :src="currentVehicle.Images[0]" />
                    </div>

                    <div class="compareDetails__benefits">
                      <p>Key specifications:</p>
                      <ul>
                        <li>
                          <span class="icon-AC_icon-cog"></span>
                          {{ currentVehicle.Type }}
                        </li>
                        <li>
                          <span class="icon-AC_icon-map"></span>
                          {{ currentVehicle.Range }} electric mile range
                        </li>
                        <li v-if="currentVehicle.Emissions">
                          <span class="icon-AC_icon-outline-leaf"></span>
                          {{ currentVehicle.Emissions }} g/km C02 emissions
                        </li>
                        <li>
                          <span class="icon-AC_icon-outline-battery"></span>
                          {{ currentVehicle.Efficiency }} mins to 80% charge
                        </li>
                        <li>
                          <span class="icon-AC_icon-road"></span>
                          £{{ currentVehicle.RoadTax }} road tax
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Transition>
              <Transition name="fade-slow">
                <div
                  class="vehicles__selected_right vehicles__selected_inner_container"
                  ref="rightSelected"
                  v-if="currentVehicleTwo"
                  :key="chosenVehicleTwoKey"
                >
                  <div class="compare__details compareDetails">
                    <div class="compareDetails__title">
                      <h2>
                        {{ currentVehicleTwo.Make }}
                        {{ currentVehicleTwo.Model }}
                      </h2>
                      <p>{{ currentVehicleTwo.Derivative }}</p>
                    </div>
                    <div class="compareDetails__img">
                      <img :src="currentVehicleTwo.Images[0]" />
                    </div>

                    <div class="compareDetails__benefits">
                      <p>Key specifications:</p>
                      <ul>
                        <li>
                          <span class="icon-AC_icon-cog"></span>
                          {{ currentVehicleTwo.Type }}
                        </li>
                        <li>
                          <span class="icon-AC_icon-map"></span>
                          {{ currentVehicleTwo.Range }} electric mile range
                        </li>
                        <li v-if="currentVehicleTwo.Emissions">
                          <span class="icon-AC_icon-outline-leaf"></span>
                          {{ currentVehicleTwo.Emissions }} g/km C02 emissions
                        </li>
                        <li>
                          <span class="icon-AC_icon-outline-battery"></span>
                          {{ currentVehicleTwo.Efficiency }} mins to 80% charge
                        </li>
                        <li>
                          <span class="icon-AC_icon-road"></span>
                          £{{ currentVehicleTwo.RoadTax }} road tax
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
            <div
              v-else
              class="vehicles__selected_empty_container"
              key="selectedVehicles"
            >
              <div
                class="vehicles__selected_left vehicles__selected_inner_container"
              >
                <div class="compare__details compareDetails">
                  <div
                    class="compareDetails__featured_img_left compareDetails__featured_img"
                  >
                    <img src="@/assets/img/left.png" />
                  </div>
                </div>
              </div>
              <div
                class="vehicles__selected_right vehicles__selected_inner_container"
              >
                <div class="compare__details compareDetails">
                  <div
                    class="compareDetails__featured_img_right compareDetails__featured_img"
                  >
                    <img src="@/assets/img/right.png" />
                  </div>
                </div>
              </div>
              <div class="vehicles__selected_empty_pattern">
                <img src="@/assets/img/1.png" alt="" />
              </div>
              <div class="vehicles__selected_empty_background_white">
                <img src="@/assets/img/2.png" alt="" />
              </div>
            </div>
          </Transition>
        </div>
      </transition>
      <div class="continue">
        <div class="continue__wave">
          <img src="@/assets/img/4.png" alt="" />
        </div>
        <div class="continue__content continueContent">
          <div class="continueContent__title">
            <p>continue</p>
            <h3>your journey</h3>
          </div>
          <router-link
            :to="compare.BottomLink"
            class="continueContent__button continueContentBtn"
          >
            <div class="circleIcon">
              <span :class="compare.BottomLinkIcon"></span>
            </div>
            <div class="continueContentBtn__content">
              <h4>{{ compare.BottomLinkName }}</h4>
              <p>{{ compare.BottomLinkDescription }}</p>
            </div>
          </router-link>
          <div class="continueContent__home">
            <router-link to="/home" class="btn btn--outline btn--icon">
              <span class="icon-AC_interface-home"></span>
              Home menu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import Dropdown from "vue-simple-search-dropdown";

export default {
  data() {
    return {
      currentVehicleTwo: "",
      comparisonViewActive: false,
      selectedType: "Battery Electric Vehicle",
      swiperOptions: {
        slidesPerView: 1,
        loop: true
      },
      chosenVehicleOneKey: 0,
      chosenVehicleTwoKey: 0
    };
  },

  components: {
    Dropdown
  },
  mounted() {
    this.selectedType = this.resultCategory;
      window.setTimeout(function(){ 
        document.getElementsByName('selectType')[0].readOnly = true;
        document.getElementsByName('SelectCar1')[0].readOnly = true;
        document.getElementsByName('SelectCar2')[0].readOnly = true;
        }, 500);
       
    
  },
  computed: {
    ...mapGetters({
      vehicles: "getVehicles",
      currentVehicle: "getCurrentVehicle",
      compare: "getCompare",
      resultCategory: "getCarCategory",
      activeModal: "getModalDrive"
    }),
    selectedVehicles() {
      let selectedCarCategory = this.resultCategory;
      let vehicles;
      switch (selectedCarCategory) {
        case "All":
          vehicles = this.allVehiclesKeyList;
          break;
        case "Battery Electric Vehicle":
          vehicles = this.allBEVKeyList;
          break;
        case "Full Hybrid":
          vehicles = this.allFullHybridKeyList;
          break;
        case "Hybrid Electric Vehicle":
          vehicles = this.allFullHybridKeyList;
          break;
        case "Plug-in Hybrid":
          vehicles = this.allPlugInHybridKeyList;
          break;
        case "Plug-in Hybrid Electric Vehicle":
          vehicles = this.allPlugInHybridKeyList;
          break;
      }
      return vehicles;
    },
    allVehicles() {
      return this.vehicles;
    },
    allBEV() {
      const vehicles = _.filter(this.vehicles, {
        Type: "Battery electric vehicle"
      });
      return vehicles;
    },
    allPlugInHybrid() {
      const vehicles = _.filter(this.vehicles, { Type: "Plug in hybrid" });
      return vehicles;
    },
    allFullHybrid() {
      const vehicles = _.filter(this.vehicles, { Type: "Hybrid" });
      return vehicles;
    },

    allVehiclesKeyList() {
      let carList = [];
      this.allVehicles.forEach(car => {
        carList.push({ id: car.CapCode, name: car.Make + " " + car.Model });
      });
      return carList;
    },
    allBEVKeyList() {
      let carList = [];
      this.allBEV.forEach(car => {
        carList.push({ id: car.CapCode, name: car.Make + " " + car.Model });
      });
      return carList;
    },
    allPlugInHybridKeyList() {
      let carList = [];
      this.allPlugInHybrid.forEach(car => {
        carList.push({ id: car.CapCode, name: car.Make + " " + car.Model });
      });
      return carList;
    },
    allFullHybridKeyList() {
      let carList = [];
      this.allFullHybrid.forEach(car => {
        carList.push({ id: car.CapCode, name: car.Make + " " + car.Model });
      });
      return carList;
    },
    placeholderText() {
      let selectedCarCategory = this.resultCategory;
      let placeholdertext;
      switch (selectedCarCategory) {
        case "Hybrid Electric Vehicle":
          placeholdertext = "Full Hybrid";
          break;
        case "Plug-in Hybrid Electric Vehicle":
          placeholdertext = "Plug-in Hybrid;";
          break;
        default:
          placeholdertext = selectedCarCategory;
      }
      return placeholdertext;
    }
  },
  methods: {
    ...mapActions({
      setCurrentVehicle: "setCurrentVehicle",
      setCurrentVehicleTwo: "setCurrentVehicleTwo",
      setCarCategory: "setCarCategory"
    }),

    updateCategory(payload) {
      if (payload.id) {
        this.setCarCategory(payload.id);
      }
    },

    setActiveVehicleOne(selectedVehicle) {
      const payload = this.vehicles.find(
        vehicle => vehicle.CapCode == selectedVehicle.id
      );
      this.setCurrentVehicle(payload);
      this.chosenVehicleOneKey++;
      /*this.$nextTick(function() {
          if(this.comparisonViewActive){
          this.$refs.leftSelected.$el.classList.value + ' fadeOut'
            setTimeout(function(){
              this.chosenVehicleOneKey++;            
            }, 2000);
        }
      
      });*/
    },
    setActiveVehicleTwo(selectedVehicle) {
      const payload = this.vehicles.find(
        vehicle => vehicle.CapCode == selectedVehicle.id
      );
      this.currentVehicleTwo = payload;
      this.chosenVehicleTwoKey++;
      /*this.$nextTick(function() {
        if(this.comparisonViewActive){
          this.$refs.rightSelected.$el.classList.value + ' fadeOut';        
            setTimeout(function(){            
              this.chosenVehicleTwoKey++; 
            }, 2000);
        }
      });*/
    },
    showComparisonView() {
      this.comparisonViewActive = true;
    }
  }
};
</script>
