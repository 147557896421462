<template>
  <section class="questionsWrapper" v-if="question && change">
    <div class="container">
      <div class="iconTextIntro">
        <span class="icon-AC_icon-electric-vehicle"></span>
        <h3>{{ change.Title }}</h3>
        <p>{{ change.Subtitle }}</p>
      </div>
      <div class="questions">
        <div class="questions__progressBar progressBar">
          <p v-if="!endOfQuestions">QUESTION {{ startingIndex + 1 }}</p>
          <p v-else>
            all questions complete!
          </p>
          <div>
            <div
              class="progressBar__progress"
              :style="`width: ${currentWidth}%`"
            ></div>
          </div>
        </div>
        <div class="questions__questionbox questionBox">
          <QuestionGroup
            v-if="question && !getFinalResult"
            :question="question"
            @update="updateQuestion"
          />
          <FinalQuestion
            @startagain="startAgain"
            v-if="getFinalResult !== false"
          />
        </div>
      </div>
      <div class="continue">
        <div class="continue__wave">
          <img src="@/assets/img/4.png" alt="" />
        </div>
        <div class="continue__content continueContent">
          <div class="continueContent__title">
            <p>continue</p>
            <h3>your journey</h3>
          </div>
          <router-link
            :to="change.BottomLink"
            class="continueContent__button continueContentBtn"
          >
            <div class="circleIcon">
              <span :class="change.BottomLinkIcon"></span>
            </div>
            <div class="continueContentBtn__content">
              <h4>{{ change.BottomLinkName }}</h4>
              <p>{{ change.BottomLinkDescription }}</p>
            </div>
          </router-link>
          <div class="continueContent__home">
            <router-link to="/home" class="btn btn--outline btn--icon">
              <span class="icon-AC_interface-home"></span>
              Home menu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import QuestionGroup from "@/components/Questions/QuestionGroup.vue";
import FinalQuestion from "@/components/Questions/FinalQuestion.vue";
export default {
  data() {
    return {
      width: 25,
      startingIndex: 0
    };
  },
  computed: {
    ...mapGetters({
      questions: "getQuestions",
      activeQuestion: "getActiveQuestion",
      question: "getQuestion",
      getFinalResult: "getFinalResult",
      change: "getChange"
    }),
    progressWidth() {
      const qLength = 5;
      return 100 / qLength;
    },
    currentWidth() {
      const index = this.startingIndex + 1;
      if (this.getFinalResult) return 100;
      return this.progressWidth * index;
    },
    endOfQuestions() {
      if (this.startingIndex + 1 > 5 || this.getFinalResult) {
        return true;
      }
      return false;
    }
  },
  components: {
    QuestionGroup,
    FinalQuestion
  },
  created() {
    this.resetAnsweredQuestions();
    this.startAgain();
  },
  methods: {
    ...mapActions({
      setAnsweredQuestion: "setAnsweredQuestion",
      resetAnsweredQuestions: "resetAnsweredQuestions",
      getNextQuestion: "getNextQuestion",
      resetQuestions: "resetQuestions",
      setCarCategory: "setCarCategory"
    }),
    updateQuestion(question) {
      this.setAnsweredQuestion(question);
      this.getNextQuestion(question);
      this.startingIndex = this.startingIndex + 1;
    },
    updateIndex() {
      this.startingIndex = this.startingIndex + 1;
    },
    startAgain() {
      this.resetQuestions();
      // this.resetAnsweredQuestions();
      this.startingIndex = 0;
    }
  },
  mounted() {
    this.setCarCategory("all");
  }
};
</script>
