<template>
  <section class="explore" v-if="change">
    <div class="container">
      <div class="iconTextIntro">
        <span class="icon-AC_icon-electric-vehicle"></span>
        <h3>{{ change.Title }}</h3>
        <p>{{ change.Subtitle }}</p>
      </div>
      <div class="fullWidthImage">
        <img :src="change.Image" />
      </div>
      <div class="explore__assistanceBlocks assistantBlocks">
        <div class="assistantBlocks__block assistantBlockPrimary">
          <div class="circleIcon">
            <span class="icon-AC_icon-EV-finder-robot"></span>
          </div>
          <div class="assistantBlockPrimary__title">
            <h3>{{ change.BodyTitle }}</h3>
          </div>
          <div class="assistantBlockPrimary__content">
            <p>
              {{ change.BodyText }}
            </p>
          </div>
        </div>
        <div class="assistantBlocks__block assistantBlock">
          <div class="assistantBlock__content">
            <router-link to="/change/quiz" class="btn btn--primary"
              >Find your perfect ev

              <span class="icon-AC_interface-double-chevron-right"></span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="continue">
        <div class="continue__wave">
          <img src="@/assets/img/4.png" alt="" />
        </div>
        <div class="continue__content continueContent">
          <div class="continueContent__title">
            <p>continue</p>
            <h3>your journey</h3>
          </div>
          <router-link
            :to="change.BottomLink"
            class="continueContent__button continueContentBtn"
          >
            <div class="circleIcon">
              <span :class="change.BottomLinkIcon"></span>
            </div>
            <div class="continueContentBtn__content">
              <h4>{{ change.BottomLinkName }}</h4>
              <p>{{ change.BottomLinkDescription }}</p>
            </div>
          </router-link>
          <div class="continueContent__home">
            <router-link to="/home" class="btn btn--outline btn--icon">
              <span class="icon-AC_interface-home"></span>
              Home menu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      change: "getChange"
    })
  },
  components: {},
  methods: {}
};
</script>
