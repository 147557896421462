import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VueAwesomeSwiper from "vue-awesome-swiper";

// import style
//import "swiper/swiper-bundle.min.css";

import "./registerServiceWorker";

import IdleVue from "idle-vue";

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 180000,
  events: ["mousemove", "keydown", "mousedown", "touchstart"]
});

Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
