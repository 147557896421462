import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Learn from "../views/Learn.vue";
import Change from "../views/Change.vue";
import Questions from "../views/Questions.vue";
import Drive from "../views/Drive.vue";
import Commercial from "../views/Commercial.vue";
import Start from "../views/Start.vue";
import Charge from "../views/Charge.vue";
import Business from "../views/Business.vue";
import BusinessQuiz from "../views/BusinessQuiz.vue";
import Sliders from "../views/Sliders.vue";
import Compare from "../views/Compare.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/sliders",
    name: "Sliders",
    component: Sliders
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/learn",
    name: "Learn",
    component: Learn
  },
  {
    path: "/change",
    name: "Change",
    component: Change
  },
  {
    path: "/charge",
    name: "Charge",
    component: Charge
  },
  {
    path: "/business-use-quiz-homepage",
    name: "BusinessQuiz",
    component: BusinessQuiz
  },
  {
    path: "/business-user",
    name: "Business",
    component: Business
  },
  {
    path: "/change/quiz",
    name: "Quiz",
    component: Questions
  },
  {
    path: "/drive",
    name: "drive",
    component: Drive
  },
  {
    path: "/commercial",
    name: "Commercial",
    component: Commercial
  },
  {
    path: "/compare",
    name: "Compare",
    component: Compare
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/Start",
    name: "Start",
    component: Start
  }
];

const router = new VueRouter({
  // scrollBehavior() {
  //   return {
  //     x: 0,
  //     y: 0
  //   };
  // },
  routes
});

export default router;
