import Vue from "vue";
import Vuex from "vuex";

// import _ from 'lodash';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeCarModal: false,
    carcategory: "all",
    finalresult: false,
    question: {},
    currentVehicle: false,
    Content: {},
    Vehicles: [
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      },
      {
        CapCode: "BMI8152RXTA4",
        Make: "BMW",
        Model: "i8",
        Derivative: "2dr Auto Roadster",
        Type: "Plug-in Hybrid",
        Range: 31,
        ListPrice: 126935,
        Battery: 12,
        Acceleration: 5,
        Efficiency: 3,
        Emissions: 46,
        MilesPerGallon: 141,
        ConnectorType: "Type 2",
        Images: [
          "  BMI8152RXTA4-1.jpg",
          "  BMI8152RXTA4-2.jpg",
          "  BMI8152RXTA4-3.jpg",
          "  BMI8152RXTA4-4.jpg",
          "  BMI8152RXTA4-5.jpg",
          "  BMI8152RXTA4-6.jpg",
          "  BMI8152RXTA4-7.jpg",
          "  BMI8152RXTA4-8.jpg",
          "  BMI8152RXTA4-9.jpg",
          "  BMI8152RXTA4-10.jpg",
          "  BMI8152RXTA4-11.jpg",
          "  BMI8152RXTA4-12.jpg"
        ],
        RoadTax: "0",
        ChargePoint: "Required",
        ACVMBCHRental: "1352.84",
        ACVMPCHRental: "1623.4"
      }
    ],
    questionAnswers: [],
    questions: [
      {
        id: 1,
        question: "Where do you park your car?",
        answers: [
          "Private off-street parking",
          "Communal parking",
          "On street parking"
        ]
      },
      {
        id: 2,
        question: "How much do you drive through the week?",
        answers: [
          "Mostly just to work and back",
          "Round trip to work but with other destination(s)",
          "I am driving constantly",
          "Rarely"
        ]
      },
      {
        id: 3,
        question: "Can you charge at work?",
        answers: ["Yes", "no"]
      },
      {
        id: 4,
        question: "How regularly do you need to make journeys of 100+ miles?",
        answers: [
          "Multiple times a week",
          "A couple of times a month",
          "Rarely",
          "Never"
        ]
      }
    ]
  },
  getters: {
    getModalDrive: state => {
      return state.activeCarModal;
    },
    getContent: state => {
      return state.Content;
    },
    getCarCategory: state => {
      return state.carcategory;
    },
    getFinalResult: state => {
      return state.finalresult;
    },
    getMenuItems: state => {
      if (state.Content) return state.Content.MenuItems;
    },
    getQuestion: state => {
      if (state.question) return state.question;
    },
    getBusiness: state => {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Business User") {
            return item;
          }
        });
        return content[0];
      }
    },
    getCharge: state => {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Charge") {
            return item;
          }
        });
        return content[0];
      }
    },
    getChange: state => {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Change" || item.Name === "Explore") {
            return item;
          }
        });
        return content[0];
      }
    },
    getDrive: state => {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Drive" || item.Name === "Discover") {
            return item;
          }
        });
        return content[0];
      }
    },
    getCompare: state => {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Compare" || item.Name === "Explore") {
            return item;
          }
        });
        return content[0];
      }
    },
    getLearn: state => {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Learn") {
            return item;
          }
        });
        return content[0];
      }
    },
    getBusinessQuiz: state => {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Business Use - Quiz Homepage") {
            return item;
          }
        });
        return content[0];
      }
    },
    getFeaturedVehicles: state => {
      if (state.Content.FeaturedVehicles) return state.Content.FeaturedVehicles;
    },
    getCommercial: state => {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Commercial") {
            return item;
          }
        });
        return content[0];
      }
    },
    getVehicles: state => {
      return state.Vehicles;
    },
    getQuestions: state => {
      return state.questions;
    },
    getActiveQuestion: state => {
      return state.activeQuestion;
    },
    getCurrentVehicle: state => {
      return state.currentVehicle;
    },
    getCurrentVehicleTwo: state => {
      return state.currentVehicleTwo;
    }
  },
  mutations: {
    setAnsweredQuestion(state, payload) {
      state.questionAnswers.push(payload);
    },
    resetAnsweredQuestions(state) {
      state.questionAnswers = [];
    },
    setCurrentVehicle(state, payload) {
      state.currentVehicle = payload;
    },
    setCurrentVehicleTwo(state, payload) {
      state.currentVehicleTwo = payload;
    },
    setVehicles(state, payload) {
      state.Vehicles = payload;
    },
    setContent(state, payload) {
      state.Content = payload;
    },
    setOriginalQuestion(state) {
      if (state.Content.Children) {
        let content = state.Content.Children.filter(item => {
          if (item.Name === "Change" || item.Name === "Explore") {
            return item;
          }
        });
        state.question = content[0].Children[0].Children[0];
      } else {
        state.question = {};
      }

      // if (
      //   state.Content.Children &&
      //   state.Content.Children[2].Children[0].Children[0]
      // ) {
      //   state.question = state.Content.Children[2].Children[0].Children[0];
      // } else {
      //   state.question = {};
      // }
    },
    setQuestion(state, payload) {
      state.question = payload;
    },
    setFinalResult(state, payload) {
      state.finalresult = payload;
    },
    setCarCategory(state, payload) {
      state.carcategory = payload;
    },
    toggleModal(state, payload) {
      state.activeCarModal = payload;
    }
  },
  actions: {
    setActiveQuestion(context, payload) {
      context.commit("setActiveQuestion", payload);
    },
    setAnsweredQuestion(context, payload) {
      context.commit("setAnsweredQuestion", payload);
    },
    resetAnsweredQuestions(context) {
      context.commit("resetAnsweredQuestions");
    },
    setCurrentVehicle(context, payload) {
      context.commit("setCurrentVehicle", payload);
    },
    setCarCategory(context, payload) {
      context.commit("setCarCategory", payload);
    },
    getVehicles(context) {
      fetch(`/api/export/vehicles`)
        .then(response => response.json())
        .then(data => {
          context.commit("setVehicles", data.Vehicles);
        });
    },
    getContent(context) {
      fetch(`/api/export/content`, {
        cache: "no-cache"
      })
        .then(response => response.json())
        .then(data => {
          context.commit("setContent", data);
          context.commit("setOriginalQuestion");
        });
    },
    resetQuestions(context) {
      context.commit("setFinalResult", false);
      context.commit("setCarCategory", "all");
      context.commit("setOriginalQuestion");
    },
    toggleModal(context, payload) {
      context.commit("toggleModal", payload);
    },
    getNextQuestion(context, payload) {
      const currentQ = context.getters.getQuestion;
      let nextQ;
      if (payload === "yes") {
        nextQ = currentQ.Children[0];
      } else {
        nextQ = currentQ.Children[1];
      }
      if (nextQ.Result !== "") {
        context.commit("setFinalResult", nextQ);
        context.commit("setCarCategory", nextQ.Name);
      } else {
        context.commit("setQuestion", nextQ);
      }
    }
  },
  modules: {}
});
