<template>
  <section class="discover" v-if="drive">
    <transition name="pop" appear>
      <VehicleModal
        :vehicle="currentVehicle"
        @closed="closeModal"
        v-show="activeModal === currentVehicle.CapCode + currentVehicle.Make"
        class="carDetails"
      >
      </VehicleModal>
    </transition>
    <div class="container">
      <div class="iconTextIntro" v-if="drive">
        <span class="icon-AC_icon-type-PHEV"></span>
        <h3>{{ drive.Title }}</h3>
        <p>{{ drive.Subtitle }}</p>
      </div>
      <div class="vehicles">
        <div class="vehicles__tabs">
          <div class="tabs tabs--flexGrow">
            <button
              @click="updateCategory('all')"
              :class="
                `tabs__item tabItem tabItem--withText tabItem--vehicle ${
                  selectedVehicles === 'all' ? 'tabItem--active' : ''
                }`
              "
            >
              <span class="icon-AC_icon-all-vehicles"></span>
              <p>all vehicles</p>
            </button>
            <button
              @click="updateCategory('Battery Electric Vehicle')"
              :class="
                `tabs__item tabItem tabItem--withText tabItem--vehicle ${
                  selectedVehicles === 'bev' ? 'tabItem--active' : ''
                }`
              "
            >
              <span class="icon-AC_icon-type-BEV"></span>
              <p>Battery Electric Vehicle (BEV)</p>
            </button>
            <button
              @click="updateCategory('Plug-in Hybrid')"
              :class="
                `tabs__item tabItem tabItem--withText tabItem--vehicle ${
                  selectedVehicles === 'plugin' ? 'tabItem--active' : ''
                }`
              "
            >
              <span class="icon-AC_icon-type-PHEV"></span>
              <p>Plug-in Hybrid (PHEV)</p>
            </button>
            <button
              @click="updateCategory('Full Hybrid')"
              :class="
                `tabs__item tabItem tabItem--withText tabItem--vehicle tabItem--full ${
                  selectedVehicles === 'full' ? 'tabItem--active' : ''
                }`
              "
            >
              <span class="icon-EngineBolt"></span>
              <p>Full Hybrid (HEV)</p>
            </button>
          </div>
        </div>
        <div class="vehicles__swipe vehicles__swipe--top">
          <div class="swipe">
            <p>
              <span class="icon icon-AC_interface-swipe-left"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></span>
              <span>Swipe</span>
              <span class="icon icon-AC_interface-swipe-right"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></span>
            </p>
          </div>
        </div>

        <div class="vehicles__cards">
          <swiper
            :class="
              `swiper all ${selectedVehicles === 'all' ? 'slide-in' : ''}`
            "
            ref="mySwiperAll"
            :options="swiperOptions"
            v-show="selectedVehicles === 'all'"
          >
            <swiper-slide
              v-for="(group, index) in groupedVehicles"
              :key="index"
            >
              <div class="cardGroup">
                <div
                  class="card"
                  v-for="(vehicle, index) in group"
                  :key="index"
                  @click="setActiveVehicle(vehicle)"
                >
                  <div class="card__details cardDetails">
                    <div class="cardDetails__img">
                      <img :src="vehicle.Images[0]" />
                    </div>
                    <div class="cardDetails__title">
                      <h4>{{ vehicle.Make }} {{ vehicle.Model }}</h4>
                      <p>{{ vehicle.Derivative }}</p>
                    </div>
                    <div class="cardDetails__benefits">
                      <ul>
                        <template
                          v-if="vehicle.Type === 'Battery electric vehicle'"
                        >
                          <li>
                            <span class="icon-AC_icon-map"></span>
                            {{ vehicle.Range }} electric mile range
                          </li>
                          <li>
                            <span class="icon-AC_icon-outline-battery"></span>
                            {{ vehicle.Efficiency }} mins to 80% charge
                          </li>
                          <li>
                            <span class="icon-AC_icon-cog"></span>
                            {{ vehicle.Type }}
                          </li>
                          <li>
                            <span class="icon-AC_icon-road"></span>
                            £{{ vehicle.RoadTax }} road tax
                          </li>
                        </template>
                        <template v-if="vehicle.Type === 'Plug in hybrid'">
                          <li>
                            <span class="icon-AC_icon-map"></span>
                            {{ vehicle.Range }} electric mile range
                          </li>
                          <li>
                            <span class="icon-AC_icon-outline-battery"></span>
                            {{ vehicle.Efficiency }} mins to 80% charge
                          </li>
                          <li>
                            <span class="icon-AC_icon-cog"></span>
                            {{ vehicle.Type }}
                          </li>
                          <li>
                            <span class="icon-AC_icon-road"></span>
                            £{{ vehicle.RoadTax }} road tax
                          </li>
                        </template>
                        <template v-if="vehicle.Type === 'Hybrid'">
                          <li>
                            <span class="icon-AC_icon-cog"></span>
                            {{ vehicle.Type }}
                          </li>
                          <li>
                            <span class="icon-AC_icon-map"></span>
                            {{ vehicle.Range }} miles per gallon
                          </li>
                          <li v-if="vehicle.Emissions">
                            <span class="icon-AC_icon-outline-leaf"></span>
                            {{ vehicle.Emissions }} g/km C02 emissions
                          </li>
                          <li>
                            <span class="icon-AC_icon-road"></span>
                            £{{ vehicle.RoadTax }} road tax
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                  <div class="card__cta cardCta">
                    <button>
                      <span
                        class="icon-AC_interface-double-chevron-right"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- BEV -->
          <swiper
            :class="
              `swiper bev ${selectedVehicles === 'bev' ? 'slide-in' : ''}`
            "
            ref="mySwiperBev"
            :options="swiperOptions"
            v-show="selectedVehicles === 'bev'"
          >
            <swiper-slide v-for="(group, index) in groupedBEV" :key="index">
              <div class="cardGroup">
                <div
                  class="card"
                  v-for="(vehicle, index) in group"
                  :key="index"
                  @click="setActiveVehicle(vehicle)"
                >
                  <div class="card__details cardDetails">
                    <div class="cardDetails__img">
                      <img :src="vehicle.Images[0]" />
                    </div>
                    <div class="cardDetails__title">
                      <h4>{{ vehicle.Make }} {{ vehicle.Model }}</h4>
                      <p>{{ vehicle.Derivative }}</p>
                    </div>
                    <div class="cardDetails__benefits">
                      <ul>
                        <li>
                          <span class="icon-AC_icon-map"></span>
                          {{ vehicle.Range }} electric mile range
                        </li>
                        <li>
                          <span class="icon-AC_icon-outline-battery"></span>
                          {{ vehicle.Efficiency }} mins to 80% charge
                        </li>
                        <li>
                          <span class="icon-AC_icon-cog"></span>
                          {{ vehicle.Type }}
                        </li>
                        <li>
                          <span class="icon-AC_icon-road"></span>
                          £{{ vehicle.RoadTax }} road tax
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card__cta cardCta">
                    <button @click="setActiveVehicle(vehicle)">
                      <span
                        class="icon-AC_interface-double-chevron-right"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- BEV -->
          <!-- plug in hybrid -->
          <swiper
            :class="
              `swiper plugin ${selectedVehicles === 'plugin' ? 'slide-in' : ''}`
            "
            ref="mySwiperPlugin"
            :options="swiperOptions"
            v-show="selectedVehicles === 'plugin'"
          >
            <swiper-slide
              v-for="(group, index) in groupedPlugInHybrid"
              :key="index"
            >
              <div class="cardGroup">
                <div
                  class="card"
                  v-for="(vehicle, index) in group"
                  :key="index"
                  @click="setActiveVehicle(vehicle)"
                >
                  <div class="card__details cardDetails">
                    <div class="cardDetails__img">
                      <img :src="vehicle.Images[0]" />
                    </div>
                    <div class="cardDetails__title">
                      <h4>{{ vehicle.Make }} {{ vehicle.Model }}</h4>
                      <p>{{ vehicle.Derivative }}</p>
                    </div>
                    <div class="cardDetails__benefits">
                      <ul>
                        <li>
                          <span class="icon-AC_icon-map"></span>
                          {{ vehicle.Range }} electric mile range
                        </li>
                        <li>
                          <span class="icon-AC_icon-outline-battery"></span>
                          {{ vehicle.Efficiency }} mins to 80% charge
                        </li>
                        <li>
                          <span class="icon-AC_icon-cog"></span>
                          {{ vehicle.Type }}
                        </li>
                        <li>
                          <span class="icon-AC_icon-road"></span>
                          £{{ vehicle.RoadTax }} road tax
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card__cta cardCta">
                    <button @click="setActiveVehicle(vehicle)">
                      <span
                        class="icon-AC_interface-double-chevron-right"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- plug in hybrid -->
          <!-- full hybrid -->
          <swiper
            :class="
              `swiper full ${selectedVehicles === 'full' ? 'slide-in' : ''}`
            "
            ref="mySwiperFull"
            :options="swiperOptions"
            v-show="selectedVehicles === 'full'"
          >
            <swiper-slide
              v-for="(group, index) in groupedFullHybrid"
              :key="index"
            >
              <div class="cardGroup">
                <div
                  class="card"
                  v-for="(vehicle, index) in group"
                  :key="index"
                  @click="setActiveVehicle(vehicle)"
                >
                  <div class="card__details cardDetails">
                    <div class="cardDetails__img">
                      <img :src="vehicle.Images[0]" />
                    </div>
                    <div class="cardDetails__title">
                      <h4>{{ vehicle.Make }} {{ vehicle.Model }}</h4>
                      <p>{{ vehicle.Derivative }}</p>
                    </div>
                    <div class="cardDetails__benefits">
                      <ul>
                        <li>
                          <span class="icon-AC_icon-cog"></span>
                          {{ vehicle.Type }}
                        </li>
                        <li>
                          <span class="icon-AC_icon-map"></span>
                          {{ vehicle.Range }} miles per gallon
                        </li>

                        <li>
                          <span class="icon-AC_icon-outline-leaf"></span>
                          {{ vehicle.Emissions }} g/km C02 emissions
                        </li>
                        <li>
                          <span class="icon-AC_icon-road"></span>
                          £{{ vehicle.RoadTax }} road tax
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card__cta cardCta">
                    <button @click="setActiveVehicle(vehicle)">
                      <span
                        class="icon-AC_interface-double-chevron-right"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- full hybrid -->
        </div>

        <!-- <div class="vehicles__swipe vehicles__swipe--bottom">
          <div class="swipe">
            <p>
              <span class="icon icon-AC_interface-swipe-left"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></span>
              <span>Swipe</span>
              <span class="icon icon-AC_interface-swipe-right"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></span>
            </p>
          </div>
        </div> -->
      </div>
      <div class="continue">
        <div class="continue__wave">
          <img src="@/assets/img/4.png" alt="" />
        </div>
        <div class="continue__content continueContent">
          <div class="continueContent__title">
            <p>continue</p>
            <h3>your journey</h3>
          </div>
          <router-link
            :to="drive.BottomLink"
            class="continueContent__button continueContentBtn"
          >
            <div class="circleIcon">
              <span :class="drive.BottomLinkIcon"></span>
            </div>
            <div class="continueContentBtn__content">
              <h4>{{ drive.BottomLinkName }}</h4>
              <p>{{ drive.BottomLinkDescription }}</p>
            </div>
          </router-link>
          <div class="continueContent__home">
            <router-link to="/home" class="btn btn--outline btn--icon">
              <span class="icon-AC_interface-home"></span>
              Home menu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import VehicleModal from "@/components/Modal/VehicleModal.vue";
export default {
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: true
      }
    };
  },
  components: {
    VehicleModal
  },
  computed: {
    ...mapGetters({
      vehicles: "getVehicles",
      currentVehicle: "getCurrentVehicle",
      drive: "getDrive",
      resultCategory: "getCarCategory",
      activeModal: "getModalDrive"
    }),
    selectedVehicles() {
      let tab = this.resultCategory;
      let selectedTab = "all";
      switch (tab) {
        case "Battery Electric Vehicle":
          selectedTab = "bev";
          break;
        case "Full Hybrid":
          selectedTab = "full";
          break;
        case "Hybrid Electric Vehicle":
          selectedTab = "full";
          break;
        case "Plug-in Hybrid":
          selectedTab = "plugin";
          break;
        case "Plug-in Hybrid Electric Vehicle":
          selectedTab = "plugin";
          break;
      }
      return selectedTab;
    },
    groupedVehicles() {
      return _.chunk(this.vehicles, 4);
    },
    groupedBEV() {
      const vehicles = _.filter(this.vehicles, {
        Type: "Battery electric vehicle"
      });
      return _.chunk(vehicles, 4);
    },
    groupedPlugInHybrid() {
      const vehicles = _.filter(this.vehicles, { Type: "Plug in hybrid" });
      return _.chunk(vehicles, 4);
    },
    groupedFullHybrid() {
      const vehicles = _.filter(this.vehicles, { Type: "Hybrid" });
      return _.chunk(vehicles, 4);
    }
  },
  methods: {
    ...mapActions({
      setCurrentVehicle: "setCurrentVehicle",
      setCarCategory: "setCarCategory",
      setModal: "toggleModal"
    }),
    closeModal() {
      this.setModal(false);
    },

    updateCategory(payload) {
      if (payload === "Plug-in Hybrid") {
        this.$refs.mySwiperPlugin.destroySwiper();
        this.$refs.mySwiperPlugin.initSwiper();
      } else if (payload === "Battery Electric Vehicle") {
        this.$refs.mySwiperBev.destroySwiper();
        this.$refs.mySwiperBev.initSwiper();
      } else if (payload === "all") {
        this.$refs.mySwiperAll.destroySwiper();
        this.$refs.mySwiperAll.initSwiper();
      } else if (payload === "Full Hybrid") {
        this.$refs.mySwiperFull.destroySwiper();
        this.$refs.mySwiperFull.initSwiper();
      }
      this.setCarCategory(payload);
    },

    setActiveVehicle(vehicle) {
      this.setCurrentVehicle(vehicle);
      let payload = vehicle.CapCode + vehicle.Make;
      this.setModal(payload);
    }
  }
};
</script>
