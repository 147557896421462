<template>
  <div class="questionBoxQuestions">
    <div :key="questionkey" class="currentQuestion slide-in">
      <div class="questionBox__question">
        <div class="messageBox messageBox--primary">
          <div class="circleIcon">
            <span class="icon-AC_icon-EV-finder-robot"></span>
          </div>
          {{ question.Name }}
        </div>
      </div>
      <div class="questionBox__answers">
        <div class="radioGroup">
          <label for="yes">
            <input
              type="radio"
              id="yes"
              :name="question.Name"
              value="yes"
              v-model="selectedQuestion"
            />
            <div class="messageBox">
              Yes
            </div>
          </label>
          <label for="no">
            <input
              type="radio"
              id="no"
              :name="question.Name"
              value="no"
              v-model="selectedQuestion"
            />
            <div class="messageBox">
              No
            </div>
          </label>
        </div>
      </div>
    </div>
    <div :key="answerkey" class="question__submit">
      <div class="submitBox">
        <button
          class="btn btn--primary"
          :disabled="selectedQuestion === ''"
          @click="updateQuestion"
        >
          Go
          <span class="icon-AC_interface-double-chevron-right"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedQuestion: "",
      questionkey: 0,
      answerkey: 1
    };
  },
  props: {
    question: {
      required: true,
      type: Object
    }
  },
  methods: {
    updateQuestion() {
      this.$emit("update", this.selectedQuestion);
      this.selectedQuestion = "";
      this.questionkey++;
      this.answerkey++;
    }
  }
};
</script>
