<template>
  <div class="questionBoxQuestions">
    <div class="currentQuestion slide-in">
      <div class="questionBox__question">
        <div class="messageBox messageBox--primary">
          <div class="circleIcon">
            <span class="icon-AC_icon-EV-finder-robot"></span>
          </div>
          <div class="messageBox__finalQuestion">
            <p>It looks like you're a</p>
            <h3>
              <span class="icon-AC_icon-type-PHEV"></span>
              {{ getFinalResult.Name }}
            </h3>
            <p>kind of person</p>
            <ul class="tickList tickList--white">
              <template
                v-if="getFinalResult.Name === 'Battery Electric Vehicle'"
              >
                <li>
                  Zero road tax
                </li>
                <li>
                  80% rapid charge in as little as 30 mins*
                </li>
                <li>
                  Ideal for shorter journeys
                </li>
              </template>
              <template
                v-else-if="
                  getFinalResult.Name === 'Plug-in Hybrid Electric Vehicle'
                "
              >
                <li>
                  Lower emissions
                </li>
                <li>
                  Benefits from both petrol and electric engine
                </li>
                <li>
                  Access to over 35,000 public chargers
                </li>
              </template>
              <template v-else>
                <li>
                  No need to plug in to charge
                </li>
                <li>
                  Longer driving range due to self charging battery
                </li>
                <li>
                  Improved fuel efficiency
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <div class="questionBox__answers">
        <div class="questionBox__answers_promptText">
          <p>What do you want to do now?</p>
        </div>
        <div class="radioGroup">
          <label for="startagain">
            <input
              type="radio"
              id="startagain"
              name="finalquestion"
              value="1"
              v-model="selectedQuestion"
            />
            <div class="messageBox">
              Start again
            </div>
          </label>
          <label for="explore">
            <input
              type="radio"
              id="explore"
              name="finalquestion"
              value="2"
              v-model="selectedQuestion"
            />
            <div class="messageBox">Explore the {{ exploreRange }} range</div>
          </label>
          <label for="compare">
            <input
              type="radio"
              id="compare"
              name="finalquestion"
              value="3"
              v-model="selectedQuestion"
            />
            <div class="messageBox" v-if="carCount > 1">
              Compare models
            </div>
          </label>
        </div>
      </div>
    </div>
    <div class="question__submit">
      <div class="submitBox">
        <button
          class="btn btn--primary"
          :disabled="selectedQuestion === ''"
          @click="doFormSelection"
        >
          Go
          <span class="icon-AC_interface-double-chevron-right"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      selectedQuestion: ""
    };
  },
  computed: {
    ...mapGetters({
      getFinalResult: "getFinalResult",
      vehicles: "getVehicles"
    }),
    exploreRange() {
      let range;
      switch (this.getFinalResult.Name) {
        case "Battery Electric Vehicle":
          range = "BEV";
          break;
        case "Plug-in Hybrid Electric Vehicle":
          range = "PHEV";
          break;
        case "Hybrid Electric Vehicle":
          range = "HEV";
          break;
      }
      return range;
    },

    carCount() {
      let count, vehicles;
      switch (this.exploreRange) {
        case "BEV":
          vehicles = _.filter(this.vehicles, {
            Type: "Battery electric vehicle"
          });
          count = vehicles.length;
          break;
        case "PHEV":
          vehicles = _.filter(this.vehicles, {
            Type: "Plug in hybrid"
          });
          count = vehicles.length;
          break;
        case "HEV":
          vehicles = _.filter(this.vehicles, {
            Type: "Hybrid"
          });
          count = vehicles.length;
          break;
      }
      return count;
    }
  },
  methods: {
    doFormSelection() {
      if (this.selectedQuestion === "1") {
        this.$emit("startagain");
      } else if (this.selectedQuestion === "2") {
        this.$router.push("/drive");
      } else {
        this.$router.push("/compare");
      }
    }
  }
};
</script>
