<template>
  <div class="sliders">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <br /><br /><br />
    <br /><br /><br />
    <br /><br /><br />
    <br /><br /><br />
    <br /><br /><br />
    <swiper ref="mySwiper" :options="swiperOptionsTwo">
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <swiper-slide data-name="test"
        ><img src="http://placehold.it/600x600"
      /></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <br /><br /><br />
    <br /><br /><br />
    <br /><br /><br />
    <br /><br /><br />
    <br /><br /><br />
    <slick class="slider-for" ref="slick" :options="slickOptions">
      <img src="http://placehold.it/600x600" />
      <img src="http://placehold.it/600x600" />
      <img src="http://placehold.it/600x600" />
      <img src="http://placehold.it/600x600" />
      <img src="http://placehold.it/600x600" />
      <img src="http://placehold.it/600x600" />
      <img src="http://placehold.it/600x600" />
    </slick>
  </div>
</template>
<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
export default {
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        loop: true,
        spaceBetween: 50
      },
      swiperOptionsTwo: {
        slidesPerView: 3,
        loop: true,
        spaceBetween: 50,
        followFinger: false
      },
      slickOptions: {
        slidesToShow: 3,
        arrows: false,
        dots: false,
        touchThreshold: 10000
      }
    };
  },
  components: {
    Slick
  }
};
</script>

<style lang="scss">
.swiper-slide img {
  width: 100%;
}
</style>
