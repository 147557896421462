<template>
  <section class="start">
    <div class="startLogo slide-in">
      <div class="startLogo__logo">
        <span class="icon-AC_brand-full-logo"
          ><span class="path1"></span><span class="path2"></span
          ><span class="path3"></span
        ></span>
      </div>
      <router-link to="/home" class="startLogo__desc">
        <h1>touch to start</h1>
        <span class="icon-AC_icon-start-touch"></span>
      </router-link>
    </div>
    <div class="startTagline slide-in">
      <h3>Driving<br />Innovation</h3>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
