<template>
  <section class="business" v-if="business">
    <transition-group name="pop" appear>
      <template v-for="(block, index) in business.Children">
        <LearnModal
          :content="block"
          :id="index"
          :key="index"
          @closed="closeModal"
          v-if="activeModal === block.ID"
        />
      </template>
    </transition-group>
    <div class="container">
      <div class="iconTextIntro">
        <span :class="business.TopIcon"></span>
        <h3>{{ business.Title }}</h3>
        <p>{{ business.Subtitle }}</p>
      </div>
      <div class="learn__blocks">
        <div
          @click="setActiveModal(block)"
          class="textOverlayImageCta"
          v-for="block in business.Children"
          :key="block.id"
        >
          <div class="textOverlayImageCta__image">
            <img :src="block.Image" alt="" />
          </div>
          <div class="textOverlayImageCta__overlay">
            <h4>{{ block.Title }}</h4>
            <p>{{ block.Subtitle }}</p>
          </div>
          <div class="textOverlayImageCta__cta">
            <button>
              <span class="icon-AC_interface-double-chevron-right"></span>
            </button>
          </div>
        </div>
        <!-- <div class="textOverlayImageCta">
          <div class="textOverlayImageCta__image">
            <img src="http://placehold.it/1639x620" alt="" />
          </div>
          <div class="textOverlayImageCta__overlay">
            <h4>How do they work?</h4>
            <p>What are the different<br />kinds of electric vehicle?</p>
          </div>
          <div class="textOverlayImageCta__cta">
            <button @click="activeModal = 'howdotheywork'">
              <span class="icon-AC_interface-double-chevron-right"></span>
            </button>
          </div>
        </div>
        <div class="textOverlayImageCta">
          <div class="textOverlayImageCta__image">
            <img src="http://placehold.it/1639x620" alt="" />
          </div>
          <div class="textOverlayImageCta__overlay">
            <h4>Why change?</h4>
            <p>What are the benefits of<br />having an electric vehicle?</p>
          </div>
          <div class="textOverlayImageCta__cta">
            <button @click="activeModal = 'whychange'">
              <span class="icon-AC_interface-double-chevron-right"></span>
            </button>
          </div>
        </div> -->
      </div>
      <div class="continue">
        <div class="continue__wave">
          <img src="@/assets/img/4.png" alt="" />
          <img src="@/assets/img/2.png" alt="" />
          <img src="@/assets/img/3.png" alt="" />
          <img src="@/assets/img/5.png" alt="" />
        </div>
        <div class="continue__content continueContent">
          <div class="continueContent__title">
            <p>continue</p>
            <h3>your journey</h3>
          </div>
          <router-link
            :to="business.BottomLink"
            class="continueContent__button continueContentBtn"
          >
            <div class="circleIcon">
              <span :class="business.BottomLinkIcon"></span>
            </div>
            <div class="continueContentBtn__content">
              <h4>{{ business.BottomLinkName }}</h4>
              <p>{{ business.BottomLinkDescription }}</p>
            </div>
          </router-link>
          <div class="continueContent__home">
            <router-link to="/home" class="btn btn--outline btn--icon">
              <span class="icon-AC_interface-home"></span>
              Home menu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import LearnModal from "@/components/Modal/LearnModal.vue";
export default {
  data() {
    return {
      activeModal: "",
      activeTab: "leaf",
      swiperOptions: {
        slidesPerView: 1,
        loop: true
      }
    };
  },
  computed: {
    ...mapGetters({
      business: "getBusiness"
    })
  },
  components: {
    LearnModal
  },
  methods: {
    closeModal() {
      this.activeModal = "";
      this.activeTab = "leaf";
    },
    setActiveModal(block) {
      this.activeModal = block.ID;
    }
  }
};
</script>
