var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal__bg",on:{"click":() => _vm.$emit('closed')}}),_c('div',{staticClass:"modal__in"},[_c('div',{staticClass:"modal__tap",on:{"click":() => _vm.$emit('closed')}},[_c('h3',[_vm._v("Tap to close")])]),_c('div',{staticClass:"modal__content modalContent"},[_c('div',{staticClass:"modalContent__header"},[(_vm.allInfo.length > 1)?[_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.allInfo),function(info,index){return _c('swiper-slide',{key:index},[_c('video',{ref:"video",refInFor:true,attrs:{"autoplay":index === 0 ? true : false,"loop":""}},[_c('source',{attrs:{"src":info.Image,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)]:[(_vm.infoContent)?_c('img',{attrs:{"src":_vm.infoContent.Image}}):_vm._e()]],2),_c('div',{staticClass:"modalContent__body"},[(_vm.content.Information.length > 1)?[_c('div',{staticClass:"tabs tabs--flexGrow"},_vm._l((_vm.content.Information),function(item,index){return _c('button',{key:index,class:`tabs__item tabItem tabItem--withText ${
                  _vm.activeTab === index ? 'tabItem--active slide-in' : ''
                }`,on:{"click":function($event){return _vm.setActiveTab(index)}}},[_c('span',{class:item.Icon}),_c('p',[_vm._v(_vm._s(item.IconText))])])}),0),_vm._l((_vm.content.Information),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === index),expression:"activeTab === index"}],key:index,class:`content content--halfed ${
                _vm.activeTab === index ? 'slide-in' : 'slide-out'
              }`},[_c('div',{class:`intro ${!_vm.infoContent.Subtitle ? 'negMarg' : ''}`},[_c('p',{staticClass:"intro__subtitle"},[_vm._v(_vm._s(item.Subtitle))]),_c('h3',[_vm._v(_vm._s(item.Title))]),(item.List)?_c('ul',{staticClass:"tickList"},_vm._l((item.List),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),_c('div',{staticClass:"intro__icon"},[_c('span',{class:`${item.Icon}`})])]),_c('div',{staticClass:"description"},[_c('div',{domProps:{"innerHTML":_vm._s(item.Body)}})])])})]:[(_vm.infoContent)?_c('div',{class:`content content--halfed ${
                !_vm.infoContent.Subtitle ? 'negMarg' : ''
              }`},[_c('div',{staticClass:"intro"},[_c('p',{staticClass:"intro__subtitle"},[_vm._v(_vm._s(_vm.infoContent.Subtitle))]),_c('h3',[_vm._v(_vm._s(_vm.infoContent.Title))]),(_vm.infoContent.List)?_c('ul',{staticClass:"tickList"},_vm._l((_vm.infoContent.List),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),_c('div',{staticClass:"intro__icon"},[_c('span',{class:`${_vm.infoContent.Icon}`})])]),_c('div',{staticClass:"description"},[(_vm.infoContent)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent.Body)}}):_vm._e()])]):_vm._e()]],2),_c('div',{staticClass:"modalContent__footer"},[_c('a',{staticClass:"btn btn--primary",attrs:{"href":_vm.content.Link},on:{"click":function($event){$event.preventDefault();return _vm.setModal(_vm.content)}}},[_vm._v(_vm._s(_vm.content.LinkName)+" "),_c('span',{staticClass:"icon-AC_interface-double-chevron-right"})])])]),_c('div',{staticClass:"modal__tap",on:{"click":() => _vm.$emit('closed')}},[_c('h3',[_vm._v("Tap to close")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }