<template>
  <div class="modal">
    <div class="modal__bg" @click="() => $emit('closed')"></div>
    <div class="modal__in">
      <!--<div class="modal__tap" @click="() => $emit('closed')">
        <h3>Tap to close</h3>
      </div> -->
      <div class="modal__content modalContent">
        <div class="modalContent__header">
          <div class="carDetails__header">
            <div class="headerImg" v-if="vehicle">
              <img :src="vehicle.Images[0]" />
            </div>
            <div class="headerCarTitle">
              <h1 class="modal__closebtn" @click="() => $emit('closed')">X</h1>
              <h3>{{ vehicle.Make }} {{ vehicle.Model }}</h3>
              <p>{{ vehicle.Derivative }}</p>
            </div>
            <div class="headerInfo">
              <span class="icon-AC_icon-outline-steeringwheel"></span>
              <p>speak to an innovation genius to book a test drive</p>
            </div>
          </div>
        </div>
        <div class="modalContent__body">
          <div class="carDetails__body">
            <div class="bodySpecs">
              <h4>Key specifications:</h4>
              <ul>
                <li>
                  <span class="icon-AC_icon-cog"></span>
                  {{ vehicle.Type }}
                </li>
                <li>
                  <span class="icon-AC_icon-outline-route"></span>
                  <template v-if="getType === 'PHEV' || getType === 'BEV'">
                    up to {{ vehicle.Range }} electric mile range**
                  </template>
                  <template v-else>
                    {{ vehicle.Range }} miles per gallon**
                  </template>
                </li>
                <li>
                  <span class="icon-AC_icon-outline-leaf"></span>
                  {{ vehicle.Emissions }} g/km C02 emissions
                </li>
                <template v-if="getType === 'PHEV' || getType === 'BEV'">
                  <li v-if="vehicle.Efficiency !== ' '">
                    <span class="icon-AC_icon-outline-battery"></span>
                    {{ vehicle.Efficiency }} mins to 80% charge*
                  </li>
                </template>
                <li>
                  <span class="icon-AC_icon-road"></span>
                  £{{ vehicle.RoadTax }} Road tax
                </li>
                <template v-if="getType === 'PHEV' || getType === 'BEV'">
                  <li v-if="vehicle.ChargePoint !== ' '">
                    <span class="icon-AC_icon-charge-point-1"></span>
                    charge point required
                  </li>
                </template>
              </ul>

              <h4>Pricing</h4>
              <div class="bodySpecs__pricing">
                <div class="pricingHeader">
                  <div class="btnGroup">
                    <button
                      @click="pricing = 'personal'"
                      :class="
                        `btn btn--body btn--icon ${
                          pricing !== 'personal' ? 'btn--inactive' : ''
                        }`
                      "
                    >
                      <span class="icon-AC_icon-user"></span>
                      Personal
                    </button>
                    <button
                      @click="pricing = 'business'"
                      :class="
                        `btn btn--body btn--icon ${
                          pricing !== 'business' ? 'btn--inactive' : ''
                        }`
                      "
                    >
                      <span class="icon-AC_icon-business-user-building"></span>
                      Business
                    </button>
                  </div>
                </div>
                <div class="pricingDetails">
                  <div
                    class="pricingDetails__price"
                    v-if="pricing === 'personal'"
                  >
                    <span>From</span>
                    <p>{{ vehicle.ListPrice }}</p>
                  </div>
                  <div class="pricingDetails__price" v-else>
                    <span>From</span>
                    <p>{{ vehicle.ListPrice }}</p>
                  </div>
                  <div
                    class="pricingDetails__price"
                    v-if="pricing === 'business'"
                  >
                    <span>Per month</span>
                    <p>{{ vehicle.ListPrice }}</p>
                  </div>
                  <div class="pricingDetails__price" v-else>
                    <span>Per month</span>
                    <p>{{ vehicle.ListPrice }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bodyGallery">
              <h4>Image gallery:</h4>
              <div class="bodyGallery__wrapper">
                <div class="topGallery">
                  <slick
                    class="slider-for"
                    ref="slick"
                    :options="slickTopOptions"
                  >
                    <template v-for="(image, index) in vehicle.Images">
                      <img v-if="index < 12" :key="index + 1" :src="image" />
                    </template>
                  </slick>
                  <div class="topGallery__swipe">
                    <div class="swipeCarousel">
                      <span class="icon icon-AC_interface-swipe-left"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span
                      ></span>
                      <span>Swipe</span>
                      <span class="icon icon-AC_interface-swipe-right"
                        ><span class="path1"></span><span class="path2"></span
                        ><span class="path3"></span
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="bottomGallery">
                  <slick
                    class="slider-nav"
                    ref="slicknav"
                    :options="slickBottomOptions"
                  >
                    <template v-for="(image, index) in vehicle.Images">
                      <img
                        v-if="index < 12"
                        :key="index + image"
                        @click="slickGoTo(index)"
                        :src="image"
                      />
                    </template>
                  </slick>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modalContent__footer">
          <div class="carPricingDetails">
            <h5>
              <template v-if="getType === 'HEV'"
                >**Everyday use may differ and is dependant on various
                factors.</template
              >
              <template v-if="getType === 'PHEV' || getType === 'BEV'">
                *Based on fastest charge available - ask for more details.<br />
                **Everyday use may differ and is dependant on various factors.
              </template>
            </h5>
            <br /><br />
            <!-- <div class="carPricingDetails__prices">
                            <ul>
                                <li>
                                    <span>Monthly payment</span>
                                    <p>£295</p>
                                </li>
                                <li>
                                    <span>Deposit</span>
                                    <p>£295</p>
                                </li>
                                <li>
                                    <span>Term (months)</span>
                                    <p>49</p>
                                </li>
                                <li>
                                    <span>Fixed interest rate </span>
                                    <p>2.52%</p>
                                </li>
                                <li>
                                    <span>Cash price</span>
                                    <p>£21,772</p>
                                </li>
                                <li>
                                    <span>Credit amount</span>
                                    <p>£19,046</p>
                                </li>
                                <li>
                                    <span>Guaranteed future value </span>
                                    <p>£7,518</p>
                                </li>
                                <li>
                                    <span>Total amount payable </span>
                                    <p>£21,973</p>
                                </li>
                                <li>
                                    <span>Renault Deposit</span>
                                    <p>£2,431</p>
                                </li>
                                <li>
                                    <span>Contract mileage </span>
                                    <p>32,000</p>
                                </li>
                                <li>
                                    <span>Excess mileage charge </span>
                                    <p>8.0p per mile</p>
                                </li>
                                <li>
                                    <span>Representative </span>
                                    <p>4.9% APR</p>
                                </li>
                            </ul>
                        </div> -->
          </div>
        </div>
      </div>
      <div class="modal__tap" @click="() => $emit('closed')">
        <h3>Tap to close</h3>
      </div>
    </div>
  </div>
</template>
<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  props: {
    vehicle: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      slickTopOptions: {
        slidesToShow: 1,
        asNavFor: ".slider-nav",
        arrows: false,
        dots: false
      },
      slickBottomOptions: {
        slidesToShow: 12,
        asNavFor: ".slider-for",
        arrows: false,
        dots: false
      },
      pricing: "personal"
    };
  },
  computed: {
    getType() {
      let type;
      switch (this.vehicle.Type) {
        case "Battery electric vehicle":
          type = "BEV";
          break;
        case "Plug in hybrid":
          type = "PHEV";
          break;
        case "Hybrid":
          type = "HEV";
          break;
      }
      return type;
    }
  },
  components: {
    Slick
  },
  beforeUpdate() {
    if (this.$refs.slick && this.$refs.slicknav) {
      this.$refs.slick.destroy();
      this.$refs.slicknav.destroy();
    }
  },
  updated() {
    this.$nextTick(function() {
      if (this.$refs.slick && this.$refs.slicknav) {
        this.$refs.slick.create(this.slickTopOptions);
        this.$refs.slicknav.create(this.slickBottomOptions);
      }
    });
  },
  created() {
    // this.$refs['slick'].reSlick();
    // this.$refs['slicknav'].reSlick();
  },
  methods: {
    closeModal: function() {},
    slickGoTo(index) {
      this.$refs["slick"].$el.slick.slickGoTo(parseInt(index));
    }
  }
};
</script>
