<template>
  <div id="app">
    <main
      :class="
        `main ${!isBottomPadding ? 'main--noPadBottom' : ''} ${
          isMenu ? 'main--menu' : ''
        }`
      "
    >
      <!-- <lottie-animation
        ref="lottieanim"
        :class="`waveTop ${!showLottie ? 'hiddenWave' : ''}`"
        path="lottie/New AC Wave_01_v3.json"
        :loop="true"
        :width="2160"
      /> -->
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<style lang="scss">
#app {
}
</style>
<script>
import { mapActions } from "vuex";
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  data() {
    return {
      messageStr: "Hello"
    };
  },
  onIdle() {
    this.$router.push("/home");
  },
  components: {
    // LottieAnimation
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    isBottomPadding() {
      if (this.currentRoute === "/" || this.currentRoute === "/home") {
        return false;
      }
      return true;
    },
    isMenu() {
      if (this.currentRoute === "/home") {
        return true;
      }
      return false;
    },
    showLottie() {
      if (this.$route.name === "Home") {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions({
      getVehicles: "getVehicles",
      getContent: "getContent"
    })
    // handleAnimation: function(anim) {
    //   console.log(anim);
    //   this.anim = anim;
    //   this.anim.setSpeed(this.animationSpeed);
    // }
  },
  mounted() {
    // this.$refs.lottieanim.speed = 0.05;
    this.getContent();
    this.getVehicles();
  }
};
</script>
