<template>
  <section class="learn" v-if="learn">
    <transition-group name="pop" appear>
      <template v-for="(block, index) in learn.Children">
        <LearnModal
          @setmodal="setActiveModalFromChild"
          :content="block"
          :id="index"
          :key="index"
          @closed="closeModal"
          v-if="activeModal === block.ID"
        />
      </template>
    </transition-group>
    <div class="container">
      <div class="iconTextIntro">
        <span :class="learn.TopIcon"></span>
        <h3>{{ learn.Title }}</h3>
        <p>{{ learn.Subtitle }}</p>
      </div>
      <div class="learn__blocks">
        <div
          @click="setActiveModal(block, index)"
          class="textOverlayImageCta"
          v-for="(block, index) in learn.Children"
          :key="block.id"
        >
          <div class="textOverlayImageCta__image">
            <img :src="block.Image" alt="" />
          </div>
          <div class="textOverlayImageCta__overlay">
            <h4>{{ block.Title }}</h4>
            <p>{{ block.Subtitle }}</p>
          </div>
          <div class="textOverlayImageCta__cta">
            <button>
              <span class="icon-AC_interface-double-chevron-right"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="continue">
        <div class="continue__wave">
          <img src="@/assets/img/4.png" alt="" />
        </div>
        <div class="continue__content continueContent">
          <div class="continueContent__title">
            <p>continue</p>
            <h3>your journey</h3>
          </div>
          <router-link
            :to="learn.BottomLink"
            class="continueContent__button continueContentBtn"
          >
            <div class="circleIcon">
              <span :class="learn.BottomLinkIcon"></span>
            </div>
            <div class="continueContentBtn__content">
              <h4>{{ learn.BottomLinkName }}</h4>
              <p>{{ learn.BottomLinkDescription }}</p>
            </div>
          </router-link>
          <div class="continueContent__home">
            <router-link to="/home" class="btn btn--outline btn--icon">
              <span class="icon-AC_interface-home"></span>
              Home menu
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import LearnModal from "@/components/Modal/LearnModal.vue";
export default {
  data() {
    return {
      activeModal: "",
      activeTab: "leaf",
      swiperOptions: {
        slidesPerView: 1,
        loop: true
      }
    };
  },
  computed: {
    ...mapGetters({
      learn: "getLearn"
    })
  },
  components: {
    LearnModal
  },
  methods: {
    closeModal() {
      this.activeModal = "";
      this.activeTab = "leaf";
    },
    setActiveModalFromChild(id) {
      if (this.learn.Children[id].Link === "/change/") {
        this.$router.push("/change/");
      } else {
        this.activeModal = this.learn.Children[id].ID;
      }
    },
    setActiveModal(block, index) {
      if (index === 2) {
        this.$router.push("/change/");
      } else {
        this.activeModal = block.ID;
      }
    }
  }
};
</script>
